import React, { createContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import { ThemeProvider } from 'emotion-theming'

import { theme, reset } from '../styles'
import i18n from '../../config/i18n'
import Footer from './Footer'

import 'typeface-lora'
import 'typeface-source-sans-pro'

const globalStyle = css`
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.black};
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.greyDarker};
    background-color: ${theme.colors.bg};
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.primary};
  }
  a {
    color: ${theme.colors.primary};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: 700;
    font-style: italic;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  @media (max-width: ${theme.breakpoints.m}) {
    html {
      font-size: 16px !important;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    h1 {
      font-size: 2.369rem !important;
    }
    h2 {
      font-size: 1.777rem !important;
    }
    h3 {
      font-size: 1.333rem !important;
    }
    h4 {
      font-size: 1rem !important;
    }
    h5 {
      font-size: 0.75rem !important;
    }
    h6 {
      font-size: 0.563rem !important;
    }
  }
`

const Logo = styled.div<any>`
  position: fixed;
  top: 1rem;
  left: 1rem;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    position: absolute;
  }

  img {
    width: 4rem;

    @media (max-width: ${props => props.theme.breakpoints.s}) {
      width: 3rem;
    }
  }
`

const LocaleSwitcher = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
`

const query = graphql`
  query LayoutQuery {
    allPrismicHomepage {
      edges {
        node {
          lang
          data {
            footer {
              html
            }
          }
        }
      }
    }
    # file(relativePath: { eq: "logo-white.svg" }) {
    #   childImageSharp {
    #     fluid(maxWidth: 100) {
    #       ...GatsbyImageSharpFluid
    #     }
    #   }
    # }
  }
`

const LocaleContext = createContext<any>({})

const Layout = ({ children, pageContext: { locale } }) => {
  const data = useStaticQuery(query)
  const footer = data.allPrismicHomepage.edges
    .filter(edge => edge.node.lang === locale)
    .map(r => r.node.data.footer.html)
    .toString()

  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <ThemeProvider theme={theme}>
        <Global styles={globalStyle} />
        <Logo>
          {/* <Img fluid={data.file.childImageSharp.fluid} /> */}
          <img
            // src={require('../images/logo-white.svg')}
            src="/logo-white.svg"
            alt="Wait And See Agency"
          />
        </Logo>
        <LocaleSwitcher data-name="locale-switcher">
          <Link hrefLang="fr-fr" to="/">
            FR
          </Link>{' '}
          /{' '}
          <Link hrefLang="en-us" to="/en">
            EN
          </Link>
        </LocaleSwitcher>
        {children}
        <Footer>
          <div dangerouslySetInnerHTML={{ __html: footer }} />
        </Footer>
      </ThemeProvider>
    </LocaleContext.Provider>
  )
}

export { LocaleContext, Layout }
