module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wait And See - French Dev Agency","short_name":"Wait And See Agency","description":"Wait And See est un studio indépendant de création numérique, spécialisé dans le développement de dispositifs innovants.","start_url":"","background_color":"#ffb932","theme_color":"#ffb932","display":"minimal-ui","icon":"src/images/logo.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"dcebcff5c629b69c03e8ccd9741893de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-90769328-1"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"wasa-portfolio","accessToken":"MC5Ycy10UnhFQUFBclFLRE0t.XO-_vUDvv70v77-977-9Rk8T77-9M--_vUzvv73vv70N77-977-9PhtJ77-977-9Ru-_vRDvv70V77-977-977-9","schemas":{"homepage":{"Main":{"title":{"type":"StructuredText","config":{"single":"heading2, heading3, heading4, heading5, heading6","label":"Title"}},"content":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","label":"Content"}},"links":{"type":"Group","config":{"fields":{"label":{"type":"Text","config":{"label":"label"}},"link":{"type":"Link","config":{"label":"link","allowTargetBlank":true}}},"label":"links"}},"footer":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","allowTargetBlank":true,"label":"Footer"}}}},"projects":{"Main":{"body":{"type":"Slices","fieldset":"Slice zone","config":{"labels":{"body":[]},"choices":{"project":{"type":"Slice","fieldset":"Project","description":"project details","icon":"face","display":"list","non-repeat":{},"repeat":{"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image"}},"name":{"type":"Text","config":{"label":"Name of the project/client"}},"year":{"type":"Text","config":{"label":"Year"}},"description":{"type":"Text","config":{"label":"Description / Infos supplémentaires"}}}}}}}}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
