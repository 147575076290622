module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Wait And See - French Dev Agency', // Navigation and Site Title
  titleAlt: 'Wait And See Agency', // Title for JSONLD
  description:
    'Wait And See est un studio indépendant de création numérique, spécialisé dans le développement de dispositifs innovants.',
  headline: 'Agence de développement web avec React, Gatsby, Vue and WordPress', // Headline for schema.org JSONLD
  url: 'https://www.waitandsee.fr', // Domain of your site. No trailing slash!
  logo: '/logo.svg', // Used for SEO
  ogLanguage: 'fr_fr', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/images/logo.svg', // Used for manifest favicon generation
  shortName: 'WaitAndSee', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Johann Pinson', // Author for schemaORGJSONLD
  themeColor: '#ffb932',
  backgroundColor: '#ffb932',

  twitter: '@johannpinson', // Twitter Username
  facebook: 'Wait And See Agency', // Facebook Site Name
  googleAnalyticsID: 'UA-90769328-1',

  // skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
