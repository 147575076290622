const website = require('./website')

module.exports = {
  'fr-fr': {
    default: true,
    path: 'fr',
    locale: 'fr-fr',
    siteLanguage: 'fr',
    ogLang: 'fr_FR',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline,
    projects: 'Projets / Clients',
    category: 'Catégorie',
    categories: 'Catégories',
    was: 'était',
    were: 'étaient',
    tagged: 'tagués',
    recent: 'Récents',
    allCategories: 'Toutes les catégories',
    entries: 'entrées',
  },
  'en-us': {
    path: 'en',
    locale: 'en-us',
    siteLanguage: 'en',
    ogLang: 'en_US',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: 'French web agency',
    projects: 'Projects / Clients',
    headline: 'Web french agency for web development',
    category: 'Category',
    categories: 'Categories',
    was: 'was',
    were: 'were',
    tagged: 'tagged with',
    recent: 'Recent',
    allCategories: 'All categories',
    entries: 'entries',
  },
}
